<template>
  <div class="item-conect" @click="projectDetail">
    <div style="position: relative; z-index: 999;">
      <div class="item-header">
        <div class="project-title" :title="projectName">{{ projectName }}</div>
        <template v-if="isApply">
          <div
            :style="statusToStyle(declareStatus)"
            class="status"
          >{{ statusToStr(declareStatus) }}</div>
        </template>
        <template v-else>
          <div
            :style="(status == 2 ? 'color: #666666; background: #fff' : status == 1 ? 'color: #fff;  background: #00BA7E' : 'color: #fff;  background: #D74BFF')"
            class="status"
          > {{ status == 2 ? '已截止' : status == 1 ? '进行中' : '未开始' }}</div>
        </template>
      </div>
      <div class="city">所属城市：{{ city }}</div>
      <div class="apply-time">报名时间：{{ this.applyDate }}</div>
      <template v-if="!canApply">
        <div class="jumps">
          <el-button style="color: #216EEE" @click.stop="showCompany">企业报名数量：{{ companyNum }} <i class="el-icon-arrow-right el-icon--right"></i></el-button>
          <button class="jumps_to" @click.stop="jumpDP" v-if="[0, 4, 5, 7, 8, 9].includes(userInfo.type)">
            <span>数据大屏</span>
            <img src="@/assets/images/ic_daping@2x.png" />
          </button>
        </div>
      </template>
      <template v-else>
        <el-button
          v-if="!isApply"
          :style="(status == 2 ? 'color: #828D9E; ' : 'color: #216EEE;') + (status == 1 ? 'background: rgba(255, 255, 255, 1)' : 'background: rgba(255, 255, 255, 0.8)')"
          size="mini"
          :disabled="status!==1"
          @click.stop="gotoApply"
        >{{ status == 2 ? '已结束' : '去报名' }} <i v-if="status !== 2" class="el-icon-arrow-right el-icon--right"></i></el-button>
      </template>
    </div>
    <img :src="imgurlfull" alt="">
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    id: [String, Number],
    projectId: [String, Number],
    applyDate: String,
    imgurlfull: String,
    city: String,
    projectName: String,
    status: Number, //  0未开始 1进行中 2已结束
    declareStatus: Number,
    companyNum: Number,
    canApply: Boolean,
    isApply: Boolean,
    remark:String
  },
  data() {
    return {
      declareStatuss: [
        // 0已保存、1待审核、2审核通过、3退回修改、4审核驳回、5待服务机构确认、
        // 6服务机构拒绝、7已分配服务机构、8诊断中（成功上传第一份服务资料）、
        // 9服务完成、10已分配评分专家、11已完成
        { label: '已保存', value: 0 },
        { label: '待审核', value: 1 },
        { label: '审核通过', value: 2 },
        { label: '退回修改', value: 3 },
        { label: '审核驳回', value: 4 },
        { label: '待服务机构确认', value: 5 },
        { label: '服务机构拒绝', value: 6 },
        { label: '已分配服务机构', value: 7 },
        { label: '诊断中', value: 8 },
        { label: '服务完成', value: 9 },
        { label: '已分配评分专家', value: 10 },
        { label: '已完成', value: 11 },
        { label: '服务退回', value: 12 },
      ]
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    // 跳转大屏
    jumpDP() {
      window.open(`http://106.14.20.42/screen/#/zhfw/declaration?projectId=${this.id}`)
    },
    // 展示企业&
    showCompany() {
      this.$emit('showCompany', this.id)
    },
    // 去报名
    gotoApply() {
      // id project
      this.$emit('apply', this.id, this.remark)
    },
    projectDetail() {
      if ((this.status === 0 || this.status === 3) && this.isApply) {
        // id 报名id
        this.$emit('apply', this.projectId, this.id, this.remark,this.status)
      } else {
        this.$emit('projectDetail', this.id, this.projectId)
      }
    },
    statusToStr(status) {
      let tempStatus = status
      if (this.isApply) {
        tempStatus = tempStatus>8 && tempStatus != 12 ? 9 : tempStatus
      }
      const temp = this.declareStatuss.find(item => item.value == tempStatus)
      if (temp) {
        return temp.label
      }
      return ''
    },
    statusToStyle(status) {
      let style = ''
      if (status == 2 || status == 3 || status == 4 || status == 6 || status == 9) {
        style += 'color: #fff;'
        if (status == 2 || status == 9) {
          style += 'background: #00BA7E'
        } else {
          style += 'background: #FF934D'
        }
      } else {
        style += 'color: #216EEE; background: #FFF'
      }
      return style
    }
  }
}
</script>

<style lang="scss" scoped>
.item-conect {
  background: linear-gradient(135deg, #0834CD, #216EEE);
  // aspect-ratio: 520 / 214;
  height: 214px;
  box-sizing: border-box;
  padding: 30px 20px;
  border-radius: 2px;
  position: relative;
  &:hover {
    box-shadow: 0px 3px 6px 0px rgba(33,110,238,0.44);
  }
  .item-header {
    display: flex;
    justify-content: space-between;
    height: 24px;
    line-height: 24px;
    .project-title {
      font-weight: 600;
      color: #FFFFFF;
      font-size: 20px;
      flex: 1;
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-text-overflow: ellipsis;
      overflow: hidden;
    }
    .status {
      font-weight: 600;
      border-radius: 2px;
      height: 24px;
      font-size: 13px;
      padding: 0 8px;
    }
  }
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  .city {
    margin-top: 23px;
  }
  .apply-time {
    margin-top: 15px;
    margin-bottom: 31px;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    overflow: hidden;
    height: 14px;
    line-height: 14px;
  }
  .jumps {
    width: 100%;
    display: flex;
    align-items: center;
    .jumps_to {
      width: 110px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      border: 1px solid #FFFFFF;
      background-color: rgba(0,0,0,0);
      cursor: pointer;
      margin-left: 10px;
      span {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
  img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 166px;
    height: 137px;
  }
  .company-num {
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
